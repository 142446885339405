/* * @Author: 曹俊杰 * @Date: 2020-08-25 17:40:13 * @Module: 主播列表 */
<template>
  <div>
    <div v-show="showList">
      <div class="header">
        <h6>主播列表</h6>
        <div class="btn-wrap">
          <p class="btn"
             @click="addClick"
             v-role="['administrator']">
            <i class="el-icon-plus btn"></i>
            <span>新增</span>
          </p>
          <p class="btn"
             @click="exportClick">
            <i class="icon iconfont icon-daochu btn"></i>
            <span>导出</span>
          </p>
        </div>
      </div>
      <el-card style="margin-top:20px">
        <el-row :gutter="20">
          <el-form :inline="true"
                   :size="'small'"
                   :model="formInline"
                   class="demo-form-inline">
            <el-col :span="6">
              <el-form-item label="陌陌ID:">
                <el-input v-model="formInline.tick_account"
                          placeholder="请输入要查询的陌陌ID"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6"
                    v-role="['administrator']">
              <el-form-item label="归属人:">
                <belonger @belongerSeChange="belongerSeChange"></belonger>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="6">
              <el-form-item label="陌陌ID:">
                <el-input v-model="formInline.tick_number"
                          placeholder="请输入要查询的陌陌ID"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="6">
              <el-form-item label="经纪人:">
                <brokerSelect @selectTreeChange="selectTreeChange"
                              @clearBrokerSelect="clearBrokerSelect"
                              placeholder="请选择经纪人"></brokerSelect>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="主播昵称:">
                <el-input v-model="formInline.nick_name"
                          placeholder="请输入要查询的主播昵称"></el-input>
              </el-form-item>
            </el-col>
            <el-col>
              <el-form-item>
                <el-button type="primary"
                           icon="el-icon-search"
                           @click="req_list(true)">搜索</el-button>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <div class="alert-style">
          <el-alert title="必须完成对公结算才可参与平台结算！！！"
                    type="warning"
                    show-icon></el-alert>
        </div>
        <tablePage :tableData="tableData"
                   :currentPage="formInline.page"
                   :height="tableHeight"
                   ref="tableList"
                   @current-change="currentChange"
                   v-loading="loading"
                   :total="total"
                   id="table">
          <el-table-column label="主播信息"
                           min-width="300">
            <template slot-scope="scope">
              <div class="base-wrap">
                <el-avatar :size="64"
                           :src="scope.row.avatar"></el-avatar>
                <div class="flex flex-direction justify-around"
                     :class="scope.row.settlement_type === 0 ? 'text-red' : ''">
                  <p>
                    <span>{{ scope.row.nick_name }}</span>
                    <el-tag v-if="scope.row.origin==1"
                            type="warning"
                            size="mini"
                            style="margin-left:10px;">星探推荐</el-tag>
                  </p>
                  <p>陌陌ID：{{ scope.row.tick_account }}</p>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="详细信息"
                           min-width="180">
            <template slot-scope="scope">
              <div :class="scope.row.settlement_type === 0 ? 'text-red' : ''">
                <p>分成比：{{ scope.row.separate_ratio }}%</p>
                <p>真实姓名：{{ scope.row.name }}</p>
                <p>电话：{{ scope.row.phone }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="本月流水"
                           min-width="180">
            <template slot-scope="scope">
              <div :class="scope.row.settlement_type === 0 ? 'text-red' : ''">
                <!-- <p>职业奖励收益：{{ scope.row.profession }}</p> -->
                <p>流水（陌陌币）：{{ scope.row.month_flow }}</p>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="签约信息"
                           min-width="180">
            <template slot-scope="scope">
              <div :class="scope.row.settlement_type === 0 ? 'text-red' : ''">
                <p>所属公会：{{ scope.row.mcn }}</p>
                <p>经纪人：{{ scope.row.broker_name }}</p>
                <p v-role="['administrator']">
                  经办人：{{ scope.row.plate_form }}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="收益"
                           min-width="180">
            <template slot-scope="scope">
              <div :class="scope.row.settlement_type === 0 ? 'text-red' : ''">
                <p>本月收益：{{ scope.row.coins }}</p>
                <p>上月收益：{{ scope.row.diamond }}</p>
                <p>钱包收益：{{ scope.row.history }}</p>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="签约时间"
                           min-width="100">
            <template slot-scope="scope">
              <div :class="scope.row.settlement_type === 0 ? 'text-red' : ''">
                <p>{{ scope.row.contract_time }}</p>
              </div>
            </template>
          </el-table-column>

          <el-table-column label="对公结算"
                           min-width="130">
            <template slot-scope="scope">
              <!-- <p v-if="scope.row.settlement_type === 0"
                 style="width: 65px;font-size:12px; background: rgba(255, 59, 0, .1); border-radius: 2px;color:rgba(255, 59, 0, 1);line-height:18px;text-align:center;">
                对私结算
              </p> -->
              <div v-if="scope.row.can_invite_to_public">
                <p v-if="scope.row.settlement_type === 1"
                   style="width: 45px;font-size:12px; background: rgba(87, 214, 76, .1); border-radius: 2px;color:rgba(87, 214, 76, 1);line-height:18px;text-align:center;">
                  已同意
                </p>
                <p v-else-if="scope.row.settlement_type === 2"
                   style="width: 45px;font-size:12px; background: rgba(96, 195, 247, .1); border-radius: 2px;color:rgba(96, 195, 247, 1);line-height:18px;text-align:center;">
                  邀请中
                </p>
                <p v-else-if="scope.row.settlement_type === 3"
                   style="width: 65px;font-size:12px; background: rgba(173, 95, 234, .1); border-radius: 2px;color:rgba(173, 95, 234, 1);line-height:18px;text-align:center;">
                  未知结算
                </p>
                <p style="width: 45px;font-size:12px;border-radius: 2px;border: 1px solid #E3E3E3;line-height:18px;text-align:center;cursor: pointer;"
                   v-else
                   @click="anchorInviteToPublic(scope.row)">
                  邀请
                </p>
              </div>
              <div v-else
                   style="font-size:10px;">
                等待系统审核中...
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="120"
                           v-if="!role(['administrator', 'manager'])">
            <template slot-scope="scope">
              <router-link :to="'anchorList/anchorDetail?anchor_id=' + scope.row.anchor_id">
                <span class="handle edit">详情</span>
              </router-link>
            </template>
          </el-table-column>
          <el-table-column label="操作"
                           min-width="150"
                           v-if="role(['administrator', 'manager'])">
            <template slot-scope="scope">
              <router-link :to="'anchorList/anchorDetail?anchor_id=' + scope.row.anchor_id">
                <span class="handle edit">详情</span>
              </router-link>
              <span class="handle del"
                    @click="anchorDelete(scope.row)"
                    v-if="role(['administrator'])">删除</span>
              <br />
              <el-dropdown>
                <span class="el-dropdown-link">
                  <span style="color:#3278EB;">编辑或更多</span>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <!-- <el-dropdown-item @click.native="anchorSetInfo(scope.row)">{{
                    role(['administrator'])
                      ? '设置分成比和手机号'
                      : '修改签约信息'
                  }}</el-dropdown-item>
                  <el-dropdown-item @click.native="balanceContent = scope.row">修改余额</el-dropdown-item> -->
                  <el-dropdown-item v-if="role(['administrator', 'manager'])"
                                    @click.native="()=>starAnchorModalFn(scope.row) ">修改来源信息</el-dropdown-item>
                  <el-dropdown-item @click.native="balanceContent = scope.row">修改余额</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </tablePage>
      </el-card>
    </div>
    <router-view></router-view>

    <customModal :visible="anchorDeleteContent !== null"
                 @handleCancel="anchorDeleteContent = null"
                 @handleOk="req_anchorDelete"
                 title="删除提醒"
                 :content="
        `确定要删除主播${
          anchorDeleteContent ? anchorDeleteContent.nick_name : ''
        }么？`
      "></customModal>

    <customModal :visible="anchorSetInfoContent !== null"
                 @handleCancel="anchorSetInfoContent = null"
                 @handleOk="anchorSetInfoOk"
                 showCancelBtn
                 :title="role(['administrator'])
                      ? '设置分成比和手机号'
                      : '修改签约信息'">
      <div class="flex flex-direction align-center anchor-form-style">
        <el-form ref="anchorSetInfoForm"
                 :model="anchorSetInfoForm"
                 label-width="80px"
                 :size="'mini'">
          <el-form-item label="分成比："
                        prop="separate_ratio">
            <pushMoney style="width:300px;"
                       :filterable="false"
                       :defaultvalue="anchorSetInfoForm.separate_ratio"
                       @change="(id) => (anchorSetInfoForm.separate_ratio = id)"
                       placeholder="请选择分成比" />
          </el-form-item>
          <el-form-item v-if="role(['administrator'])"
                        label="电话："
                        prop="phone">
            <el-input v-model="anchorSetInfoForm.phone"
                      placeholder="请输入电话"></el-input>
          </el-form-item>
          <el-form-item v-if="role(['manager'])"
                        label="备注："
                        prop="description">
            <el-input v-model="anchorSetInfoForm.description"
                      placeholder="请输入备注"></el-input>
          </el-form-item>
          <el-form-item v-if="role(['manager'])"
                        label="经纪人："
                        prop="description">
            <brokerInviter @change="broker_id=>anchorSetInfoForm.broker_id=broker_id"
                           :defaultvalue="anchorSetInfoForm.broker_id"
                           style="width:100%;" />
          </el-form-item>
        </el-form>
      </div>
    </customModal>

    <balanceDialog ref="balance"
                   @submitBalanceDialog="submitBalanceDialog"
                   @cancleDialog="balanceContent = null"
                   :balanceDialogVisible="balanceContent !== null"></balanceDialog>
    <starAnchorModal :visible="starAnchorVisible"
                     :anchor_id="anchor_id"
                     :btnloading="starAnchorBtnLoading"
                     @handleOk="starAnchorOk"
                     @handleCancel="starAnchorCancel" />

  </div>
</template>
<script>
import tablePage from 'components/tablePage'
import brokerSelect from 'components/brokerSelect'
import belonger from 'components/belonger'
import api from '@/api'
import tableHeight from 'mixins/tableHeight'
import pushMoney from 'components/formComponent/pushMoney'
import brokerInviter from 'components/formComponent/brokerInviter'
import balanceDialog from '@/views/agentmanage/components/balanceDialog'
import starAnchorModal from './components/starAnchorModal'
export default {
  data () {
    return {
      loading: true,
      formInline: {
        tick_account: null, // 陌陌号
        manager: null, //归属人
        // tick_number: null, //陌陌ID
        broker_id: null, // 经纪人
        nick_name: null, // 主播昵称
        page: 1, // 分页
      },
      tableData: [],
      total: 10,
      anchorDeleteContent: null,
      anchorSetInfoContent: null,
      balanceContent: null,
      anchorSetInfoForm: {
        phone: '', //电话
        separate_ratio: '', //分成比
        anchor_id: '', //主播id
        description: '', // 手机号
        broker_id: null
      },
      starAnchorVisible: false,
      anchor_id: null,
      starAnchorBtnLoading: false
    }
  },
  components: {
    tablePage,
    brokerSelect,
    belonger,
    pushMoney,
    balanceDialog,
    brokerInviter,
    starAnchorModal
  },
  mixins: [tableHeight],
  created () {
    this.req_list(true)
  },
  mounted () { },
  methods: {
    addClick () {
      this.$router.push({ path: '/anchor/anchorAdd' })
    },
    exportClick () {
      this.$excelDownload(this.formInline, 'api/anchor/list/export')
    },
    async _initState (params = {}) {
      this.loading = true
      const {
        status_code,
        meta: {
          pagination: { total },
        },
        message,
      } = await api.anchorListApi(params)
      if (status_code === 200) {
        this.total = total
        this.tableData = message
        this.loading = false
      }
    },
    currentChange (page) {
      this.formInline.page = page
      this.req_list()
    },
    //  选择归属人回调
    belongerSeChange (id) {
      this.formInline.manager = id
    },
    //  选择经纪人回调
    selectTreeChange (id) {
      this.formInline.broker_id = id
    },
    // 清空经纪人筛选回调
    clearBrokerSelect () {
      this.formInline.broker_id = null
    },
    // 请求列表
    req_list (refresh) {
      if (refresh) this.formInline.page = 1
      this._initState(this.formInline)
    },
    // 请求删除主播
    async req_anchorDelete () {
      const { anchor_id } = this.anchorDeleteContent
      const { status_code, message } = await api.anchorDelete({
        anchor_id,
      })
      if (status_code === 200) {
        this.$message.success(message)
        this.anchorDeleteContent = null
        this.req_list(true)
      } else {
        this.$message.error(message)
      }
    },
    anchorDelete (item) {
      const { anchor_id, nick_name } = item
      this.anchorDeleteContent = { anchor_id, nick_name }
    },
    //邀请主播对公结算
    anchorInviteToPublic (item) {
      const { anchor_id } = item
      this.$confirm(
        '主播统一次日起结算方式将变为公会对公，是否确认邀请该主播？',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
        .then(() => {
          this.req_anchorInviteToPublic(anchor_id)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消邀请对公结算',
          })
        })
    },
    //请求邀请主播对公结算
    async req_anchorInviteToPublic (anchor_id) {
      const { status_code, message } = await api.anchorInviteToPublic({
        anchor_id,
      })
      if (status_code === 200) {
        this.$message.success(message)
        const list = [...this.tableData]
        list.forEach((item, index) => {
          if (item.anchor_id == anchor_id) list[index].settlement_type = 2
        })
        this.tableData = list
      } else {
        this.$message.error(message)
      }
    },
    // 设置分成比和手机号
    anchorSetInfo (item) {
      this.anchorSetInfoContent = item
      this.anchorSetInfoForm.phone = item.phone
      this.anchorSetInfoForm.anchor_id = item.anchor_id
      this.anchorSetInfoForm.broker_id = item.broker_id
      this.anchorSetInfoForm.separate_ratio = item.separate_ratio_value
      this.anchorSetInfoForm.description = item.description
    },
    anchorSetInfoOk () {
      if (this.role(['administrator']))
        this.req_anchorSetInfo(this.anchorSetInfoForm)
      else
        this.req_updateContractInfo(this.anchorSetInfoForm)
    },
    // 请求设置分成比和手机号
    async req_anchorSetInfo (params) {
      const { status_code, message } = await api.anchorSetInfo(params)
      console.log(status_code, message)
      if (status_code === 200) {
        this.$message.success(message)
        const list = [...this.tableData]
        list.forEach((item, index) => {
          if (item.anchor_id == params.anchor_id) {
            list[index].phone = params.phone
            list[index].separate_ratio = params.separate_ratio
          }
        })
        this.tableData = list
        this.anchorSetInfoContent = null
      } else if (status_code === 422) {
        Object.keys(message).map(item => {
          this.$message.error({
            message: message[item]
          })
        })
      } else {
        this.$message.error(message)
      }
    },
    // 请求设置分成比和手机号  (代理)
    async req_updateContractInfo (params) {
      const { status_code, message } = await api.updateContractInfo(params)
      if (status_code === 200) {
        this.$message.success(message)
        const list = [...this.tableData]
        list.forEach((item, index) => {
          if (item.anchor_id == params.anchor_id) {
            list[index].description = params.description
            list[index].separate_ratio = params.separate_ratio
          }
        })
        this.tableData = list
        this.anchorSetInfoContent = null
      } else if (status_code === 422) {
        Object.keys(message).map(item => {
          this.$message.error({
            message: message[item]
          })
        })
      } else {
        this.$message.error(message)
      }
    },
    // 修改余额 确定
    async submitBalanceDialog (res) {
      const { status_code, message } = await api.walletTransferApi({
        ...res,
        user_type: 'anchor',
        user_id: this.balanceContent.anchor_id,
      })
      if (status_code == 200) {
        this.$message.success(message)
        this.balanceContent = null
        this.req_list(true)
      } else {
        if (typeof message == 'object') {
          Object.keys(message).forEach((item) =>
            this.$message.error(message[item])
          )
        } else {
          this.$message.error(message)
        }
      }
    },
    // 显示模态框 传id
    starAnchorModalFn ({ anchor_id }) {
      console.log("xianshi")
      this.starAnchorVisible = true
      this.anchor_id = anchor_id
    },
    // 模态框取消
    starAnchorCancel () {
      this.starAnchorVisible = false
    },
    // 模态框确认
    starAnchorOk (item) {
      this._update_star_anchor(item)
    },
    // 修改星探资料
    async _update_star_anchor (parms) {
      this.starAnchorBtnLoading = true
      const { status_code, message } = await this.$api.updateStarBroker(parms);
      this.starAnchorBtnLoading = false
      if (status_code == 200) {
        this.starAnchorCancel()
        this.$message.success({ message })
        this.req_list(true)
      } else if (status_code === 422) {
        Object.keys(message).map(item => {
          this.$message.error({
            message: message[item]
          })
        })
      } else {
        this.$message.error(message)
      }
    },
  },
  computed: {
    showList () {
      return this.$route.name == 'anchorList'
    },
  },
}
</script>
<style lang="less" scoped>
@import "./style.less";
.notice-style {
  height: 400px;
  line-height: 40px;
  scrollbar-width: none; /* firefox */
  -ms-overflow-style: none; /* IE 10+ */
  overflow-x: hidden;
  overflow-y: auto;
}
.notice-main-style /deep/ .el-dialog__body {
  padding: 0 20px !important;
}
.notice-main-style /deep/ .el-dialog__header {
  padding: 0 !important;
}
.notice-style::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
.alert-style {
  margin-bottom: 9px;
  & /deep/ .el-alert--warning {
    color: #3278eb;
    background: rgba(96, 195, 247, 0.1);
    font-size: 13px;
  }
}
.handle {
  font-size: 14px;
  cursor: pointer;
}
.edit {
  color: #3278eb;
}
.del {
  color: #666666;
  line-height: 14px;
  border-left: 1px solid #e3e3e3;
  padding-left: 12px;
  margin-left: 12px;
}
.text-red {
  p {
    color: #ff0029 !important;
  }
}
.anchor-form-style /deep/ .el-input__inner {
  height: 40px;
}
.anchor-form-style /deep/ .el-form-item__label {
  line-height: 40px !important;
}
.anchor-form-style /deep/ .el-form-item {
  margin-bottom: 40px;
}
</style>
